import React from "react";

function FirstMeeting() {
  return (
    <div className="text-center mx-8 flex flex-col">
      <p className="text-7xl mb-6 font-script leading-10">Pierwsze spotkanie</p>
      <p className="">
        Poznali się w 2011 roku, czyli w pierwszej klasie gimnazjum. Przez
        długie lata była to raczej koleżeńska relacja. Rozmowy SMS-owe i lata
        starań Piotrka wydawały się zakończyć już fiaskiem…<br></br>
        Nieoczekiwany zwrot akcji nastąpił w szkole średniej. Każde z nich było
        już w innym otoczeniu i przypadkiem spotkali się w autobusie relacji
        Kolbuszowa-Lipnica, gdy to Piotrek wracał na weekend do domu z Rzeszowa,
        a Sylwia kończyła dzień w kolbuszowskim liceum. I coś zaklikało…
        <br></br>Ich znajomość ponownie wkroczyła na inne tory. Odnowili kontakt
        pod pretekstem „wzajemnej pomocy w nauce” i zaczęły się pierwsze
        spotkania, aż pewnego dnia ta przyjacielska relacja przerodziła się w
        związek.<br></br> W lipcu 2015 roku zostali już oficjalnie parą. Potem
        były przygotowania do matury, dwie studniówki, a następnie studia w
        Krakowie.<br></br> Te sześć lat minęło bardzo szybko, a z tego okresu
        pozostało wiele niesamowitych wspomnień.
      </p>
    </div>
  );
}

export default FirstMeeting;
